import React from 'react';
import Button from './Button';

const SmallProjectButtons = ({ projects }) => {

    return projects.map (project => {
        return(

            <div className = "my-5 mx-6 flex justify-center">
                <div className = "border-8 border-darkGreen w-full h-fit shadow-md shadow-black rounded-md">
                    <div className = "flex relative w-full h-1/2 justify-stretch">
                        <div className = "z-40 absolute top-0 right-0 text-neutral font-helvetica text-sm bg-darkGreen p-2">{project.language}</div>
                            <img src = {project.preview} alt = "Project Preview" className = "object-fill h-full w-full brightness-75"></img>
                        </div>

                    <div className = "w-full h-1/2 bg-neutral border-t-2 border-darkGreen">
                        <div className = "flex justify-center py-2">
                            <div className = "py-2 px-10 text-neutral font-sora rounded-xl text-center bg-darkGreen">
                                {project.title}
                            </div>
                        </div>

                        <div className = "text-black font-helvetica py-2 px-4">
                            {project.desc}    
                        </div>

                        <div className = "flex justify-evenly p-2 items-center font-sora">
                            <span className = "">
                                <a href={project.git} target="_blank">
                                    <Button className = "">Code</Button>
                                </a>
                            </span>

                            <span className = "font-sora">
                                <a href = {project.demo} target = "_blank">
                                    <Button className = "">Demo</Button>
                                </a> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
};

export default SmallProjectButtons;
