import React from 'react';
import Button from './Button';

const ProjectButtons = ({ projects }) => {
    
    return projects.map (project => {
        return(

            <div className = "m-4 border-8 border-darkGreen w-1/3 min-h-max h-fill mx-6 shadow-md shadow-black rounded-md">
                <div className = "flex relative w-full h-1/2 justify-stretch">
                    <div className = "z-40 absolute top-0 right-0 text-neutral font-helvetica text-sm bg-darkGreen p-2">{project.language}</div>
                        <img src = {project.preview} alt = "Project Preview" className = "object-fill h-full w-full brightness-75"></img>
                </div>

                <div className = "h-1/2 w-full bg-neutral border-t-2 border-darkGreen pb-6">
                    <div className = "flex justify-center my-2">
                        <div className = "py-1 px-20 text-neutral rounded-xl text-center bg-darkGreen font-sora">
                            {project.title}
                        </div>
                    </div>

                    <div className = "text-black font-helvetica mt-2 mb-2 px-4 font-helvetica h-1/2">
                        {project.desc}    
                    </div>

                    <div className = "flex justify-evenly p-2 items-center font-sora h-1/6">
                        <span className = "">
                            <a href={project.git} target="_blank">
                                <Button className = "">Code</Button>
                            </a>
                        </span>

                        <span className = "font-sora">
                            <a href = {project.demo} target = "_blank">
                                <Button className = "">Demo</Button>
                            </a> 
                        </span>
                    </div>
                </div>
            </div>
        );
    });
};

export default ProjectButtons;


/*

                <div className = "grid grid-rows-2 divide y">
                    <div className = "">
                        <img src = {project.preview} alt = "Project Preview" className = "object-cover"></img>  
                    </div>
                    
                    <div className = "bg-neutral border-t-2 border-darkGreen">
                        <div className = "flex justify-center py-2">
                            <div className = "py-2 px-20 text-neutral rounded-xl text-center bg-darkGreen">
                                {project.title}
                            </div>
                        </div>

                        <div className = "text-black py-2 px-4">
                            {project.desc}    
                        </div>

                        <div className = "flex justify-evenly p-2 items-center">
                            <span className = "">
                                <a href={project.git} target="_blank">
                                    <Button className = "">Code</Button>
                                </a>
                            </span>

                            <span className = "">
                                <a href = {project.demo} target = "_blank">
                                    <Button className = "">Demo</Button>
                                </a> 
                            </span>
                        </div>
                    </div>

                </div>








*/