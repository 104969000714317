import React from 'react';
import { Link } from 'react-scroll';
import heroPicture from './heroPicture3.png';

class Home extends React.Component {
    render() {
        const linkedin = "https://www.linkedin.com/in/sean-pourgoutzidis";

        return (
            <div id = "Home" className = "h-screen w-full">
                
                <div className = "hidden md:block">
                    <div style={{ backgroundImage:`url(${heroPicture})` }} className = "flex flex-col w-full h-screen block bg-cover bg-center">

                        <div className = "md:w-1/3 h-screen bg-green">
                            <div className = "flex justify-center">
                                <div className = "w-2/3 h-4 border-b-4 border-darkGreen"></div>
                            </div>

                            <div className = "mt-5 text-center p-5 text-almond text-8xl font-sora mb-4">Hello!</div>

                            <div className = "pl-20 lg:text-center text-7xl text-darkGreen font-sora">I'm</div>
                            <div className = "pr-20 lg:text-center text-right text-7xl text-darkGreen font-sora">Sean</div>

                            <div className = "px-16 mt-10 lg:mt-20 font-sora text-almond text-lg text-left">
                                I’m a third-year computer engineering student at the University of Toronto. I currently program in C/C++, Python, SQL, Java and React JS. This website serves as a place to highlight some of my projects.
                            </div>

                            <a className = "flex justify-center" href = {linkedin} target = "_blank">
                                <div className = "transition w-2/3 mt-16 lg:mb-10 py-2 bg-darkGreen hover:bg-green text-almond text-center text-md shadow-md shadow-black rounded-md">
                                    Connect with Me    
                                </div>
                            </a>



                        </div>

                        <div className = "mt-auto flex justify-center">
                            <div className = "w-40 h-16 lg:h-24 p-4 rounded-t-3xl bg-almond"></div>
                        </div>

                    </div>
                </div>



                <div className = "md:hidden flex flex-col justify-center w-full h-screen bg-green block bg-cover bg-center">

                    <div className = "p-5 bg-green">
                        <div className = "flex justify-center">
                            <div className = "mt-10 w-2/3 h-4 border-b-4 border-darkGreen"></div>
                        </div>

                        <div className = "block justify-center">
                            <div className = "">
                                <div className = "mt-5 text-center p-5 text-almond text-6xl font-sora mb-16">Hello!</div>

                                <div className = "flex justify-start pl-20">
                                    <div className = "text-left text-5xl text-darkGreen font-sora">I'm</div>
                                </div>

                                <div className = "flex w-full justify-end pr-20">
                                    <div className = "text-right text-5xl text-darkGreen font-sora">Sean</div>
                                </div>
                            </div>
                        </div>

                        <div className = "px-5 mt-20 font-sora text-almond text-md text-center">
                            I’m a third-year computer engineering student at the University of Toronto. I currently program in C/C++, Python, SQL, Java and React JS. This website serves as a place to highlight some of my projects.
                        </div>

                        <a className = "flex justify-center" href = {linkedin} target = "_blank">
                            <div className = "w-2/3 mt-10 py-2 bg-darkGreen hover:bg-hoverGreen text-almond text-center text-md shadow-md shadow-black">
                                Connect with Me    
                            </div>
                        </a>

                    </div>

                    <div className = "flex justify-center mt-auto">
                            <div className = "w-40 h-16 p-4 rounded-t-3xl bg-almond"></div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Home;

/*

            <div id = "Home" className = "bg-stone w-screen h-fit items-center border-b-8 border-x-8 border-green">
                
                <div className = "p-5 md:p-20 border-r-2 border-green">
                    <div className = "my-3 text-neutral md:mt-0 sm:mt-20 sm:text-5xl md:text-9xl font-sora flex-wrap text-center">
                        Hello!
                    </div>

                    <div className = "mt-16 text-neutral sm:3xl md:text-4xl font-sora flex-wrap text-center">
                        Welcome to my website!
                    </div>


                    <div className = "mt-20 sm:pb-6 grid grid-cols-3 divide-x justify-stretch border-b-2 border-neutral">
                        <Link to = "About" spy={true} smooth={true} offset={-90} duration={500} className = "mb-12 shadow-sm shadow-black mr-3 font-sora rounded-md bg-green text-neutral sm:xl md:text-2xl text-center hover:bg-gold sm:p-2 md:p-3 cursor-pointer">
                            About    
                        </Link>

                        <Link to = "Projects" spy={true} smooth={true} offset={-90} duration={500} className = "mb-12 shadow-sm shadow-black mx-3 font-sora rounded-md bg-green text-neutral sm:xl md:text-2xl text-center hover:bg-gold sm:p-2 md:p-3 cursor-pointer">
                            Projects    
                        </Link>

                        <Link to = "Contact" spy={true} smooth={true} offset={-50} duration={500} className = "mb-12 shadow-sm shadow-black ml-3 font-sora rounded-md bg-green text-neutral sm:xl md:text-2xl text-center hover:bg-gold sm:p-2 md:p-3 cursor-pointer">
                            Contact    
                        </Link>

                    </div>

                    <div className = "hidden md:block mt-12 mb-10 md:text-3xl font-sora text-center">
                        <span className = "text-neutral">
                            Sean Pourgoutzidis
                        </span>
                        <span className = "text-green mx-2">
                            |
                        </span>
                        <span className = "text-neutral">
                            Computer Engineering Student
                        </span>   
                    </div>

                    <div className = "md:hidden sm:mt-20 sm:mb-20 font-sora text-center">
                        <div className = "text-neutral">
                            Sean Pourgoutzidis
                        </div>
                        <div className = "text-neutral mt-10">
                            Computer Engineering Student
                        </div>    
                    </div>

                    <div className = "grid grid-cols-3 divide-x">
                        <div className = "border-r border-green text-center text-neutral text-2xl">
                            React JS
                        </div>

                        <div className = "border-x border-green text-center text-neutral text-2xl">
                            C
                        </div>
                            
                        <div className = "border-l-2 border-green text-center text-neutral text-2xl">
                            Java        
                        </div>
                    </div>
                
                </div>

            </div>




                <div style={{ backgroundImage:`url(${heroPicture})` }} className = "flex flex-col w-full h-screen block bg-cover bg-center">
                    <div className = "mt-44 ml-10 block justify-center bg-darkGreen opacity-90 w-1/2 h-fit p-8 shadow-md shadow-black">

                        <div className = "px-10 mb-5 pb-5 font-sora text-neutral text-6xl">
                            Hello!
                        </div>

                        <div className = "px-10 font-sora text-neutral text-wrap text-lg">
                            My name is Sean and I’m a second-year computer engineering student at the University of Toronto. Currently, I code with React JS, C & Java and this website serves as a place for me to highlight my personal projects.
                        </div>    


                        <div className = "pt-10 px-10 w-full pb-5">
                            <a className = "" href = {linkedin} target = "_blank">
                                <div className = "w-full py-2 bg-hoverGreen hover:bg-green text-darkGreen text-center text-md shadow-md shadow-black">
                                    Connect with Me    
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className = "mt-auto flex justify-center">
                        <div className = "w-40 h-16 p-4 rounded-t-3xl bg-almond"></div>
                    </div>
                </div>

                <div className = "mt-44 ml-10 block justify-center bg-darkGreen opacity-90 w-1/2 h-fit p-8 shadow-md shadow-black">

<div className = "px-10 mb-5 pb-5 font-sora text-neutral text-6xl">
    Hello!
</div>

<div className = "px-10 font-sora text-neutral text-wrap text-lg">
    My name is Sean and I’m a second-year computer engineering student at the University of Toronto. Currently, I code with React JS, C & Java and this website serves as a place for me to highlight my personal projects.
</div>    


<div className = "pt-10 px-10 w-full pb-5">
    <a className = "" href = {linkedin} target = "_blank">
        <div className = "w-full py-2 bg-hoverGreen hover:bg-green text-darkGreen text-center text-md shadow-md shadow-black">
            Connect with Me    
        </div>
    </a>
</div>
</div>

<div className = "mt-auto flex justify-center">
<div className = "w-40 h-16 p-4 rounded-t-3xl bg-almond"></div>
</div>
</div>                


*/