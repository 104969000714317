import React from 'react';
import { Link } from 'react-scroll';

const Header = () => {

    return (
        <div className = "w-full rounded-sm fixed left-0 top-0 sticky z-50">
            <div className = "bg-darkGreen md:flex">
                <div className = "w-full grid grid-cols-3 divide x items-center">
                    <Link to = "Home" spy={true} smooth={true} offset={-70} duration={500} className = "py-6 hover:bg-hoverGreen text-center"> 
                        <div className = "text-neutral font-sora active:text-gold cursor-pointer">
                            HOME    
                        </div>
                    </Link>

                    <Link to = "Projects" spy={true} smooth={true} offset={-70} duration={500} className = "py-6 hover:bg-hoverGreen text-center">
                        <div className = " text-neutral font-sora hover:bg-hoverGreen cursor-pointer">
                            PROJECTS
                        </div>
                    </Link>

                    <Link to = "Contact" spy={true} smooth={true} offset={0} duration={500} className = "py-6 hover:bg-hoverGreen text-center">
                        <div className = "text-neutral font-sora hover:bg-hoverGreen cursor-pointer">
                            CONTACT
                        </div>
                    </Link>

                </div>    
            </div>
        </div>
    );
};

export default Header;

/*

            <div className = "hidden md:block bg-stone py-6 md:flex md:py-4">
                <span className = "md:w-1/4 sm:w-1/4">
                    <Link to = "Home" spy={true} smooth={true} offset={-100} duration={500} className = "flex justify-center text-green md:text-5xl font-sora">
                        <div className = "w-fit h-fit border border-green p-2 cursor-pointer">
                            SP
                        </div>
                    </Link>   
                </span>
                <span className = "sm:w-3/4 md:w-3/4 grid sm:grid-cols-3 md:grid-cols-4 divide x md:justify-items-center md:justify-items-stretch items-center">
                    <Link to = "Home" spy={true} smooth={true} offset={-100} duration={500} className = "text-neutral font-sora hover:text-gold active:text-gold cursor-pointer">
                        HOME    
                    </Link>
                    <Link to = "About" spy={true} smooth={true} offset={-90} duration={500} className = "text-neutral font-sora hover:text-gold cursor-pointer">
                        ABOUT    
                    </Link>
                    <Link to = "Projects" spy={true} smooth={true} offset={-90} duration={500} className = "text-neutral font-sora hover:text-gold cursor-pointer">
                        PROJECTS
                    </Link>
                    <Link to = "Contact" spy={true} smooth={true} offset={-50} duration={500} className = "text-neutral font-sora hover:text-gold cursor-pointer">
                        CONTACT
                    </Link>                 
                </span>    
            </div>

            <div className = "md:hidden bg-stone flex py-4 w-full">
                <span className = "grid grid-cols-4 divide x justify-self-stretch items-center w-full">
                    <Link to = "Home" spy={true} smooth={true} offset={-100} duration={500} className = "flex justify-center text-green sm:text-md md:text-5xl font-sora">
                        <div className = "w-fit h-fit border border-green p-2 cursor-pointer">
                            SP
                        </div>
                    </Link>

                    <Link to = "About" spy={true} smooth={true} offset={-90} duration={500} className = "text-neutral sm:text-sm font-sora hover:text-gold cursor-pointer">
                        ABOUT    
                    </Link>
                    <Link to = "Projects" spy={true} smooth={true} offset={-90} duration={500} className = "text-neutral sm:text-sm font-sora hover:text-gold cursor-pointer">
                        PROJECTS
                    </Link>
                    <Link to = "Contact" spy={true} smooth={true} offset={-50} duration={500} className = "text-neutral sm:text-sm font-sora hover:text-gold cursor-pointer">
                        CONTACT
                    </Link>

                </span>    
            </div>







*/