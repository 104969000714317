import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    
    const form = useRef();

    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(null);

    const handleSubmitted = (submitted) => {
        if(!submitted) {
            setSubmitted(!submitted);
        }
    };

    const handleSuccess = (newSuccess) => {
        setSuccess(newSuccess);
    };

    var filled = true;

    const sendEmail = (e) => {
      
        e.preventDefault();

        const empName = document.forms["form"]["user_name"].value;
        const empEmail = document.forms["form"]["user_email"].value;
        const empMessage = document.forms["form"]["message"].value;

        if (empName === '' || empEmail === '' || empMessage === '') {
            filled = false;
        }

        handleSubmitted(submitted);
        
        if (filled) {

            handleSubmitted(submitted);
    
            emailjs.sendForm('service_cm2uz9c', 'template_lcmffj9', form.current, 'FKC373I6g-JjuTk3R')
                .then((result) => {
                    console.log(result.text);
                    handleSuccess(true);            
                }, (error) => {
                    console.log(error.text);
            });
        }

        else if (!filled) {
            handleSuccess(false);
        }

    };

    const renderStatus = () => {
        
        if (!submitted) {
            return (null);
        }

        else if (success && submitted) {
            return (
                <div className = "bg-green w-full text-neutral p-4 text-xl text-center">
                    Message Sent!
                </div>
            );
        }

        else if (!success && submitted && !filled) {
            return (
                <div className = "bg-red w-full text-neutral p-4 text-xl text-center">
                    Error! Make sure all fields are filled!
                </div>
            );
        }

    };

    return (
        <div id = "Contact" className = "md:pt-0 sm:px-2 md:px-0 bg-stone w-screen h-fit">
            <div className = "flex justify-center">
                <div className = "w-40 h-16 p-4 rounded-b-3xl bg-almond"></div>
            </div>

            <div className = "pt-8 text-neutral sm:text-4xl md:text-5xl font-sora md:pt-10 md:pl-20 ">
                <span className = "text-darkGreen">
                    |    
                </span>
                Contact    
            </div>

            <div className = "md:pb-4 text-neutral sm:text-sm sm:py-5 md:pt-0 sm:px-2 md:px-0 md:text-lg font-sora md:mx-20 md:mt-5 md:mb text-left">
                If you are interested in working together or have any inquiries feel free to contact me through my LinkedIn or using the form below!
            </div>

            <div className = "flex justify-center">
                <div className = "w-2/3 h border-b-4 border-neutral"></div>
            </div>

            <form name = "form" ref={form} onSubmit={sendEmail}>

                <div className = "md:mx-20 my-5 border border-green">
                    <div className = "bg-darkGreen w-full text-neutral text-xl font-sora h-fit p-4">
                        Your Name
                    </div>

                    <input type="text" name="user_name" placeholder = "Your Name" className= "bg-white w-full text-stone text-xl h-8 px-4 py-6 transition ease-in-out focus:text-stone focus:bg-neutral focus:border-2 focus:border-almond focus:outline-none" autocomplete = "off">
                        
                    </input>
                </div>

                <div className = "md:mx-20 my-5 border border-green">
                    <div className = "bg-darkGreen w-full text-neutral text-xl font-sora h-fit p-4">
                        Your Email
                    </div>

                    <input type="email" name="user_email" placeholder = "example@email.com" className= "bg-white w-full text-stone text-xl h-8 px-4 py-6 transition ease-in-out focus:text-stone focus:bg-neutral focus:border-2 focus:border-almond focus:outline-none" autocomplete = "off">
                        
                    </input>
                </div>

                <div className = "md:mx-20 my-5 border border-green">
                    <div className = "bg-darkGreen w-full text-neutral text-xl font-sora h-fit p-4">
                        Your Message
                    </div>

                    <textarea name="message" rows = "6" placeholder = "Your Message"  className= "px-4 py-6 form-control block text-xl w-full text-stone bg-neutral bg-clip-padding transition ease-in-out focus:text-stone focus:bg-neutral focus:border-2 focus:border-almond focus:outline-none" autocomplete = "off">
                        
                    </textarea>   
                </div>

                <div className = "md:mx-20">
                    <div className = "w-full flex justify-end">
                        <button type="submit" value="Send" className = "w-fit p-2 rounded-md bg-darkGreen text-neutral hover:bg-green py-2">
                            Submit
                        </button>
                    </div>
                </div>


            </form>

            <div className = "mt-5 mx-20 pb-5">
                {renderStatus()}
            </div>

        </div>
    );
};


export default Contact;


/*








*/