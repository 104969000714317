import React from 'react';

const Button = (props) => {

    return (
        <div>
            <div className = "px-4 py-2 rounded-md text-center bg-darkGreen text-neutral hover:bg-green">
                {props.children}
            </div>
        </div>
    );
};

export default Button;