import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { FaArrowCircleUp } from 'react-icons/fa';
import { Link } from 'react-scroll';


const Footer = () => {
    
    const linkedin = "https://www.linkedin.com/in/sean-pourgoutzidis/";

    return (
        <div className = "bg-hoverGreen h-fit w-full">
            <div className = "flex justify-center">
                <div className = "w-2/3 h-4 border-b-4 border-darkGreen"></div>
            </div>

            <div className = "grid grid-cols-3 divide x">
                <a style={{color: '#152614'}} href = {linkedin} target = "_blank" className = "hidden py-4 md:py-4 px md:px-10 md:flex justify-center">
                    <FaLinkedin size = {70} />
                </a>

                <a style={{color: '#152614'}} href = {linkedin} target = "_blank" className = "md:hidden py-4 px flex justify-center">
                    <FaLinkedin size = {40} />
                </a>

                <div className = "mt-3 md:py-4 md:px-10 text-center items-center">
                    <div className = "text-md md:text-2xl text-darkGreen font-sora">
                        Sean Pourgoutzidis
                    </div>

                    <div className = "hidden pt-2 text-xs md:text-xl text-center text-black font-sora">
                        sean.pourgoutzidis@yahoo.ca
                    </div>
                </div>

                <Link to = "Home" spy={true} smooth={true} offset={-70} duration={500} className = "hidden cursor-pointer py-4 md:py-4 px md:px-10 md:flex justify-center">
                    <div style={{color: '#152614'}} className = "flex items-center text-3xl text-neutral text-center">
                        <FaArrowCircleUp size = {70}/>
                    </div>
                </Link>

                <Link to = "Home" spy={true} smooth={true} offset={-70} duration={500} className = "md:hidden cursor-pointer py-4 px flex justify-center">
                    <div style={{color: '#152614'}} className = "">
                        <FaArrowCircleUp size = {40}/>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Footer;

/*

        <div>
            <div className = "hidden md:block pb-20 pt-5 border-t-4 border-green">
                <div className = "mt-12 mb-10 text-3xl font-sora text-center">
                    <span className = "text-neutral">
                        Sean Pourgoutzidis
                    </span>
                    <span className = "text-green mx-2">
                        |
                    </span>
                    <span className = "text-neutral">
                        Computer Engineering Student
                    </span>   
                </div>

                <div className = "grid grid-cols-3 divide-x">
                    <div className = "border-r border-green text-center text-neutral text-2xl">
                        React JS
                    </div>

                    <div className = "border-x border-green text-center text-neutral text-2xl">
                        C
                    </div>
                        
                    <div className = "border-l-2 border-green text-center text-neutral text-2xl">
                        Java        
                    </div>
                </div>
            </div>

            <div className = "md:hidden sm:mb-10 sm:pt-5 font-sora text-center border-t-4 border-green">
                <div className = "text-neutral text-xl">
                    Sean Pourgoutzidis
                </div>
                    <div className = "text-neutral text-xl mt-5">
                        Computer Engineering Student
                    </div>    
                </div>

                <div className = "md:hidden grid grid-cols-3 divide-x">
                    <div className = "border-r border-green text-center text-neutral text-xl">
                        React JS
                    </div>

                    <div className = "border-x border-green text-center text-neutral text-xl">
                        C
                    </div>
                            
                    <div className = "border-l-2 border-green text-center text-neutral text-xl">
                        Java        
                    </div>
            </div>
        </div>











*/