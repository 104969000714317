import React from 'react';
import Header from './Header';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Footer from './pages/Footer';

const App = () => {
    
    return (
      <div className = "">
        <Header />
        <Home />
        <Projects />
        <Contact />
        <Footer />

      </div>
    );
};

export default App;

/*
        <About />
        <Projects />
        <Contact />
        <Footer />

        <div>
          <Router history = {history}>
                <div>
                    <div>
                        <Header />  
                    </div>

                    <Switch>
                        <Route path = "/" exact component = {Home} />
                        <Route path = "/about" exact component = {About} />
                        <Route path = "/projects" exact component = {Projects} />
                        <Route path = "/contact" exact component = {Contact} />
                    </Switch>
                </div>
          </Router>
        </div>

*/