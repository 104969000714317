import React from 'react';
import ProjectButtons from './../ProjectButtons';
import SmallProjectButtons from './../SmallProjectButtons';

import ShoobStreamsPreview from './ShoobStreamsPreview.png';
import ShoobTubePreview from './ShoobTubePreview.png';
import ShoogleImagesPreview from './ShoogleImagesPreview.png';
import FitPicPreview from './FitPicPreview.png';
import FamilyFeudPreview from './FamilyFeudPreview.png'
import LyricLoverPreview from './LyricLoverPreview.png'
import ApplicationAssistantPreview from './ApplicationAssistantPreview.png'
import ByteMapPreview from './ByteMapPreview.png'
import DoodleJumpPreview from './DoodleJumpPreview.png'
import MatiPreview from './MatiPreview.png'
import BetulaPreview from './BetulaPreview.png'
import HurriScanPreview from './HurriScanPreview.png'
import PillPartnerPreview from './PillPartnerPreview.png'

const items = [
    {
        title: "About",
        content: "I’ve been learning React JS due to an interest in web development. My experience with React stems from an online course I took as well as the development of this website with Tailwind CSS!"
    },

    {
        title: "About",
        content: "My experience programming with C language comes from my first year programming class at UofT. Despite being lower-level than what I was used to, I find that I’ve been tending to use it more and more."
    },

    {
        title: "About",
        content: "Java was my first introduction to the world of programming. Throughout high school, I took three years of coding courses in Java which helped me fall in love with programming."
    },
];


const row0Projects = [
    {
        title: "Pill Partner",
        desc: "Microcontroller-based automatic pill dispenser to help elderly take their medication on time. Developed in a team of 2 at UofT",
        git: "https://github.com/charlottepfritz/PillPartner",
        demo: "https://drive.google.com/file/d/1OWs3rnpRt7eQpyV80M1d5LYWfoVwCZGb/view?usp=drive_link",
        preview: PillPartnerPreview,
        language: "C",
    },
    {
        title: "HurriScan",
        desc: "PyTorch Deep Learning Model for Hurricane Intensity Prediction developed in a team of 4",
        git: "https://github.com/macaroonforu/HurriScan",
        demo: "https://drive.google.com/file/d/19rEmTvcj-dvJqhJ7uodOpZdjCWpxrR81/view?usp=drive_link",
        preview: HurriScanPreview,
        language: "Python/PyTorch",
    },
    {
        title: "Betula",
        desc: "A UofT Event Finder App Developed for Software Engineering Course in a team of 7",
        git: "https://github.com/ECE444-2023Fall/project-1-web-application-design-group19-webcrafters",
        demo: "https://drive.google.com/file/d/1pp0vr91XpNafrVqG309sCjXfcplWmJHs/view?usp=sharingLinks to an external site.",
        preview: BetulaPreview,
        language: "Python/Flask"

    },
];

const row1Projects = [
    {
        title: "ApplicationAssistant",
        desc: "An automatic application tracker that cross-references a spreadsheet with your email inbox",
        git: "https://github.com/seanpourgoutzidis/ApplicationAssistant",
        demo: "https://www.youtube.com/watch?v=MTVr7DEyxlI",
        preview: ApplicationAssistantPreview,
        language: "Python",
    },
    {
        title: "ByteMap",
        desc: "A GIS application coded with C++ for ECE297 at UofT in a team of 3",
        git: "https://github.com/seanpourgoutzidis/ByteMap",
        demo: "https://youtu.be/QZlZSaMNL7M",
        preview: ByteMapPreview,
        language: "C++",
    },
];

const row2Projects = [
    {
        title: "Mati",
        desc: "Computer vision and LLM powered guide robot made with Yolov5 & HuggingFace as a proof of concept",
        git: "https://github.com/seanpourgoutzidis/Mati",
        demo: "https://www.youtube.com/watch?v=HxpIs5khI1I",
        preview: MatiPreview,
        language: "Python"

    },
    {
        title: "DoodleJump",
        desc: "C implementation of Doodle Jump on the DE1-SoC board for ECE243 at UofT",
        git: "https://github.com/hallovera/doodlejump",
        demo: "https://youtu.be/VT6EUjjL9EQ",
        preview: DoodleJumpPreview,
        language: "C"
    },

];

const row3Projects = [
    {
        title: "LyricLover",
        desc: "An app that utilizes the Genius API to download and email lyrics for any song/album",
        git: "https://github.com/seanpourgoutzidis/LyricLover",
        demo: "https://youtu.be/MbhYmHgUyok",
        preview: LyricLoverPreview,
        language: "Python",
    },
    {
        title: "Family Feud",
        desc: "An application that simulates the popular game-show family feud!",
        git: "https://github.com/seanpourgoutzidis/FamilyFeud",
        demo: "https://replit.com/@SeanPourgout/FamilyFeud#Main.java",
        preview: FamilyFeudPreview,
        language: "Java",
    },

];

const row4Projects = [
    {
        title: "FitPic",
        desc: "An outfit randomizer I designed to help choose your outfit on those days you just can’t decide!",
        git: "https://github.com/seanpourgoutzidis/FitPic",
        demo: "https://replit.com/@SeanPourgout/FitPic#main.c",
        preview: FitPicPreview,
        language: "C",
    },

    {
        title: "ShoobStream",
        desc: "This site is a low-fidelity clone of Twitch using APIs and redux stores.",
        git: 'https://github.com/seanpourgoutzidis/ShoobStreams',
        demo: 'https://youtu.be/9moxEsUg-Hs',
        preview: ShoobStreamsPreview,
        language: "React JS",

    },
];

const row5Projects = [
    {
        title: "ShoobTube",
        desc: "This site is a low-fidelity clone of YouTube using APIs",
        git: "https://github.com/seanpourgoutzidis/ShoobTube",
        demo: "https://youtu.be/uQCZ_Wl7D40",
        preview: ShoobTubePreview,
        language: "React JS",
    },
    {        
        title: "Shoogle Images",
        desc: "This site is a low-fidelity clone of Google Images using APIs",
        git: "https://github.com/seanpourgoutzidis/ShoogleImages",
        demo: "https://6309103510e1a3007779f0f5--monumental-starlight-b3d653.netlify.app/",
        preview: ShoogleImagesPreview,
        language: "React JS",
    },
];

const projects = [];
projects.push(...row0Projects);
projects.push(...row1Projects);
projects.push(...row2Projects);
projects.push(...row3Projects);
projects.push(...row4Projects);
projects.push(...row5Projects);



class Projects extends React.Component {
    
    renderSmall() {
        return (
            <div className = "bg-almond h-fit w-full">

                <div>
                    <div className = "pt-12">
                        <div className = "w-fit py-4 text-2xl bg-darkGreen text-neutral shadow-md shadow-black font-sora px-8">
                            Projects
                        </div>
                    </div>

                    <div className = "pt-10 pb-10 px-2">
                        <SmallProjectButtons projects = {projects}></SmallProjectButtons>
                    </div>
                </div>

            </div>
        );
    }

    renderMedium() {
        return (
            <div className = "hidden md:block bg-almond h-fit w-full">
                
                <div>
                    <div className = "pt-12">
                        <div className = " w-fit py-4 text-5xl bg-darkGreen text-neutral shadow-md shadow-black font-sora px-24">
                            Projects
                        </div>
                    </div>

                    <div className = "flex w-full justify-evenly pt-16 pb-10 px-20">
                        <ProjectButtons projects = {row0Projects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {row1Projects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {row2Projects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {row3Projects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {row4Projects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {row5Projects}></ProjectButtons>
                    </div>

                </div>


            </div>
        );
    }

    render() {
        return (
            <div id = "Projects">
                <div className = "hidden md:block">
                    {this.renderMedium()}
                </div>

                <div className = "md:hidden">
                    {this.renderSmall()}
                </div>
            </div>
        );
    }
}

export default Projects;



/*

            <div id = "Projects" className = "bg-stone w-screen h-fit sm:mb-10 md:pb-0">
                <div className = "text-neutral mmd:mb-0 sm:mb-10 sm:text-4xl md:text-5xl font-sora md:pt-5 md:pl-20">
                    <span className = "text-green">
                        |    
                    </span>
                    
                    <span>My Projects</span>
                       
                </div>

                <div className = "sm:m-5 md:mt-20 md:ml-20 md:mr-20 md:mb-20">
                   <div className = "sm:text-xl md:text-3xl text-neutral font-sora mb-5">
                       React JS Projects
                   </div>
                   <div>
                        <AboutCoding item = {items[0]}></AboutCoding>
                    </div>
                    <div className = "border-x border-neutral">
                        <ProjectsList projects = {reactProjects}></ProjectsList>    
                    </div> 
                </div>

                <div className = "sm:m-5 md:mt-10 md:ml-20 md:mr-20 md:mb-20">
                    <div className = "sm:text-xl md:text-3xl text-neutral font-sora mb-5">
                       C Projects
                   </div>
                   <div>
                        <AboutCoding item = {items[1]}></AboutCoding>
                    </div>
                    <div className = "border-x border-neutral">
                        <ProjectsList projects = {cProjects}></ProjectsList>    
                    </div>    
                </div>

                <div className = "sm:m-5 md:mt-10 md:ml-20 md:mr-20 md:mb-20">
                    <div className = "sm:text-xl md:text-3xl text-neutral font-sora mb-5">
                       Java Projects
                   </div>
                   <div>
                        <AboutCoding item = {items[2]}></AboutCoding>
                    </div>
                    <div className = "border-x border-neutral">
                        <ProjectsList projects = {javaProjects}></ProjectsList>
                    </div>
                </div>

            </div>

*/

/* OLD SET-UP OF PROJECTS THAT WAS NOT A GRID

<div className = "flex w-full justify-evenly pt-16 pb-10 px-20">
                        <ProjectButtons projects = {cppProjects}></ProjectButtons>
                        <ProjectButtons projects = {de1socProjects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {pythonProjects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-10 px-20">
                        <ProjectButtons projects = {reactProjects}></ProjectButtons>
                    </div>

                    <div className = "flex w-full justify-evenly pt pb-16 px-20">
                        <ProjectButtons projects = {cProjects}></ProjectButtons>
                        <ProjectButtons projects = {javaProjects}></ProjectButtons>
                    </div>

*/